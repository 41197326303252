var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.page === "SR"
        ? _c("working-sr", {
            ref: "blDetail",
            attrs: {
              "detail-bkg-no": _vm.detailBkgNo,
              "detail-sr-rno": _vm.detailSrRno,
              "new-sr-no": _vm.nextBlNo,
              "detail-arr-bkg-no": _vm.detailArrBkgNo,
              "sr-cncl-yn": _vm.srCnclYn,
            },
            on: {
              callback: _vm.goSrPage,
              goPageType: _vm.goPage,
              after: _vm.fnAddSr,
            },
          })
        : _vm._e(),
      _vm.page === "BL"
        ? _c("working-bl", {
            ref: "blDetail",
            attrs: {
              "detail-bkg-no": _vm.detailBkgNo,
              "detail-bl-no": _vm.detailBlNo,
              "new-bl-no": _vm.nextBlNo,
            },
            on: { callback: _vm.goSrPage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }