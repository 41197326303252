<template>
  <div>
    <working-sr
      v-if="page === 'SR'"
      :detail-bkg-no="detailBkgNo"
      :detail-sr-rno="detailSrRno"
      :new-sr-no="nextBlNo"
      :detail-arr-bkg-no="detailArrBkgNo"
      @callback="goSrPage"
      @goPageType="goPage"
      @after="fnAddSr"
      :sr-cncl-yn="srCnclYn"
      ref="blDetail"
    >
    </working-sr>
    <working-bl
      v-if="page === 'BL'"
      :detail-bkg-no="detailBkgNo"
      :detail-bl-no="detailBlNo"
      :new-bl-no="nextBlNo"
      @callback="goSrPage"
      ref="blDetail"
    >
    </working-bl>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'

export default {
  name: 'WorkingBLMain',
  components: {
    'working-sr': () => import('@/pages/trans/WorkingSR'),
    'working-bl': () => import('@/pages/trans/WorkingBL')
  },
  props: {
    detailBkgNo: {
      type: String,
      default: ''
    },
    detailBlNo: {
      type: String,
      default: ''
    },
    detailSrRno: {
      type: String,
      default: ''
    },
    detailNewSrRno: {
      type: String,
      default: ''
    },
    detailArrBkgNo: {
      type: Array,
      default: () => {
        return []
      }
    },
    srCnclYn: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      page: '',
      nextBlNo: ''
    }
  },
  computed: {
    ...rootComputed
  },
  mounted () {
    if (this.$ekmtcCommon.isEmpty(this.detailBlNo)) {
      this.page = 'SR'
    } else {
      this.page = 'BL'
    }

    if (this.$ekmtcCommon.isNotEmpty(this.detailNewSrRno)) {
      this.goSrPage(this.detailNewSrRno)
    }
  },
  methods: {
    print () {
      this.$refs.blDetail.print()
    },
    goSrPage (nextBlNo) {
      this.page = ''
      this.page = 'SR'
      this.nextBlNo = nextBlNo
    },
    goPage (obj) {
      this.page = obj.type
      this.nextBlNo = obj.blNo
    },
    fnAddSr (srNo) {
      this.$emit('after', srNo)
    },
    compareCheck () {
      let isChange = false
      isChange = this.$refs.blDetail.compareCheck()

      return isChange
    }
  }
}
</script>
